<template>
  <div>
    <a-card :title="$t('出库通知单详情')">
      <!-- <a-button slot="extra" type="primary" icon="printer" style="margin-right: 8px" ghost v-print="'#printContent'">
      打印
    </a-button> -->
      <a-button slot="extra" type="primary" ghost icon="left" @click="$router.go(-1)">{{ $t("返回") }}</a-button>
      <section id="printContent">
        <a-spin :spinning="spinning">
          <img class="jsbarcode" id="jsbarcode" ref="jsbarcode" />
          <a-descriptions bordered>
            <a-descriptions-item :label="$t('出库通知单号')">
              {{ detailInfo.number }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('仓库')">
              {{ detailInfo.warehouse_name }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('货主')">
              {{ detailInfo.client_name }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('往来单位')">
              {{ detailInfo.counterparty_name }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('订单类型')">
              {{ detailInfo.order_type_name }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('往来单位单号')">
              {{ detailInfo.external_number }}
            </a-descriptions-item>

            <a-descriptions-item :label="$t('联系人')">
              {{ detailInfo.customer_contact }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('联系人电话')">
              {{ detailInfo.contact_phone }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('送货地址')">
              {{ detailInfo.receiving_address }}
              <a-button
                v-if="detailInfo.receiving_address"
                type="link"
                icon="copy"
                v-clipboard:copy="detailInfo.receiving_address"
                v-clipboard:success="copySuccess"
              />
            </a-descriptions-item>

            <a-descriptions-item :label="$t('预计到货日')">
              {{ detailInfo.arrival_date }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('发货方式')">
              {{ detailInfo.delivery_method }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('付款方式')">
              {{ detailInfo.payment_method }}
            </a-descriptions-item>

            <a-descriptions-item :label="$t('备注')">
              {{ detailInfo.remark }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('附件')" :span="3">
              <a
                v-for="fileItem in detailInfo.delivery_file_items"
                :href="fileItem.file"
                :key="fileItem.id"
                style="margin-right: 16px"
                >{{ fileItem.name }}</a
              >
            </a-descriptions-item>

            <a-descriptions-item :label="$t('创建人')">
              {{ detailInfo.creator_name }}
            </a-descriptions-item>
            <a-descriptions-item :label="$t('创建时间')">
              {{ detailInfo.create_time }}
            </a-descriptions-item>
          </a-descriptions>
        </a-spin>

        <div style="margin-top: 16px">
          <a-table
            rowKey="id"
            size="small"
            :columns="curColumns"
            :data-source="materialItems"
            :loading="materialLoading"
            :pagination="false"
          >
            <div slot="material_weight" slot-scope="value, item">
              <span v-if="item.total_quantity && item.material_weight">
                {{ NP.times(item.total_quantity, item.material_weight, 0.001) }}
              </span>
            </div>
            <div slot="material_volume" slot-scope="value, item">
              <span v-if="item.total_quantity && item.material_volume">
                {{ NP.times(item.total_quantity, item.material_volume) }}
              </span>
            </div>

            <!-- <div slot="action" slot-scope="value, item">
              <a-button
                type="primary"
                size="small"
                :disabled="item.total_quantity == item.remain_quantity + item.return_quantity"
                @click="
                  () => {
                    stockOutReturnVisible = true;
                    deliveryMaterial = item.id;
                  }
                "
                >{{ $t("退库") }}</a-button
              >
            </div> -->
          </a-table>
        </div>
      </section>
    </a-card>

    <stock-out-return-modal
      v-model="stockOutReturnVisible"
      :deliveryMaterial="deliveryMaterial"
      :warehouse="detailInfo.warehouse"
      @cancel="
        stockOutReturnVisible = false;
        getDeliveryMaterial();
      "
    />
  </div>
</template>

<script>
import JsBarcode from "jsbarcode";
import { deliveryDetail, deliveryMaterials } from "@/api/stockOut";
import NP from "number-precision";

export default {
  components: {
    StockOutReturnModal: () => import("@/components/StockOutReturnModal"),
  },
  data() {
    return {
      NP,
      description: this.$t("详情"),
      spinning: false,
      materialLoading: false,
      detailInfo: {},
      materialItems: [],
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: this.$t("货品编号"),
          dataIndex: "material_number",
          key: "material_number",
        },
        {
          title: this.$t("货品名称"),
          dataIndex: "material_name",
          key: "material_name",
        },
        {
          title: this.$t("质检状态"),
          dataIndex: "status_display",
          key: "quality_inspection_status",
        },
        {
          title: this.$t("批次"),
          dataIndex: "batch_number",
          key: "batch_number",
        },
        {
          title: this.$t("数量"),
          dataIndex: "total_quantity",
          key: "total_quantity",
        },
        // {
        //   title: this.$t("待复核数量"),
        //   dataIndex: "remain_quantity",
        //   key: "remain_quantity",
        // },
        // {
        //   title: this.$t("退库数量"),
        //   dataIndex: "return_quantity",
        //   key: "return_quantity",
        // },

        {
          title: this.$t("库存状态"),
          dataIndex: "location_number",
          key: "location_number",
          customRender: (value, item, index) => {
            let recommendLocations = [];
            for (let _item of item.recommend_location_items) {
              if (_item.has_stock) {
                recommendLocations.push(`${_item.location_number}: ${_item.remain_quantity}`);
              }
            }

            return recommendLocations.join(",");
          },
        },
        // {
        //   title: this.$t("操作"),
        //   dataIndex: "action",
        //   scopedSlots: { customRender: "action" },
        // },
      ],

      deliveryMaterial: null,
      stockOutReturnVisible: false,
    };
  },
  computed: {
    totalWeight() {
      let totalWeight = 0;
      for (let item of this.materialItems) {
        if (item.material_weight) {
          totalWeight = NP.plus(totalWeight, NP.times(item.total_quantity, item.material_weight, 0.001));
        }
      }
      return totalWeight;
    },
    totalVolume() {
      let totalVolume = 0;
      for (let item of this.materialItems) {
        if (item.material_volume) {
          totalVolume = NP.plus(totalVolume, NP.times(item.total_quantity, item.material_volume));
        }
      }
      return totalVolume;
    },
    enableQualityInspection() {
      return this.$store.state.user.enableQualityInspection;
    },
    curColumns() {
      const items = [];
      for (const item of this.columns) {
        if (!this.enableQualityInspection && item.key === "quality_inspection_status") {
          continue;
        }

        items.push(item);
      }
      return items;
    },
  },
  methods: {
    initData() {
      this.getData(this.$route.query.id);
    },
    getData(id) {
      this.spinning = true;
      this.materialLoading = true;
      document.getElementById("jsbarcode").html = "";
      deliveryDetail({ id })
        .then((data) => {
          this.detailInfo = data;
          var jsbarcode = new JsBarcode(this.$refs.jsbarcode, data.number, {
            format: "CODE128",
            width: 2,
            height: 35,
            displayValue: true,
            background: "#ffffff",
            lineColor: "#000000",
          });
        })
        .finally(() => {
          this.spinning = false;
        });
      deliveryMaterials({ id })
        .then((data) => {
          this.materialItems = data;
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
    getDeliveryMaterial() {
      const deliveryOrder = this.$route.query.id;
      deliveryMaterials({ id: deliveryOrder })
        .then((data) => {
          this.materialItems = data;
        })
        .finally(() => {
          this.materialLoading = false;
        });
    },
    copySuccess() {
      this.$message.success(this.$t("复制成功"));
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
<style>
.jsbarcode {
  float: right;
}
</style>
